import React from "react"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"
import ContextStore from "@/contextStore"
import ListItemText from "@material-ui/core/ListItemText"

const LocaleButton = styled(ListItemText)`
  && {
    cursor: pointer;
  }
`

function LanguageSwitcher(props, context) {
  var {
    route: {
      state: { fullPath },
    },
  } = React.useContext(ContextStore)

  const changeLanguage = lng => {
    if (lng) {
      if (lng === "id") {
        fullPath = fullPath.replace("/en", "")
        navigate(fullPath, { replace: true })
      } else {
        fullPath = fullPath.replace("/", `/${lng}/`)
        navigate(fullPath, { replace: true })
      }
    }
  }

  const { t } = useTranslation()

  return (
    <>
      <LocaleButton onClick={() => changeLanguage("en")}>
        {t("text.english")}
      </LocaleButton>
      <ListItemText> | </ListItemText>
      <LocaleButton onClick={() => changeLanguage("id")}>
        {t("text.bahasa")}
      </LocaleButton>
    </>
  )
}

export default LanguageSwitcher
